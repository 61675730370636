<template>
  <div>
    <v-gantt-chart
      dataKey="id"
      :start-time="start"
      :end-time="end"
      :datas="executors"
      :cellHeight="cellHeight"
      :cellWidth="cellWidth"
      :hideXScrollBar="false"
      :hideYScrollBar="true"
      :scale="60"
      :showCurrentTime="true"
      :timeRangeCorrection="false"
    >
      <template
        v-slot:block="{ item }"
      >
          <div
            class="schedule-task"
            :class="{
              'schedule-task--active': item.status === $ENUM.REQUEST_STATUSES.ACTIVE,
              'schedule-task--paused': item.status === $ENUM.REQUEST_STATUSES.PAUSED,
              'schedule-task--canceled': item.status === $ENUM.REQUEST_STATUSES.CANCELED,
              'schedule-task--day-off': item.status === 'DAY_OFF',
            }"
          ></div>
      </template>
      <template v-slot:left="{data}">
        <div class="schedule-table__employee">
          <div class="schedule-table__employee-name">{{data.fullName}}</div>
          <div class="schedule-table__employee-role">
          </div>
          <div class="schedule-table__employee-icon">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><circle cx="8" cy="8" r="8" fill="#C4C4C4"/><path d="M6.977 12.191V5.626h1.732v6.565H6.977zm.87-7.482c-.31 0-.558-.081-.745-.244a.798.798 0 01-.28-.631c0-.262.094-.472.28-.631.187-.163.435-.244.744-.244.31 0 .558.081.744.244.187.159.28.37.28.631a.791.791 0 01-.286.63c-.186.164-.432.245-.738.245z" fill="#fff"/></svg>
          </div>
        </div>
      </template>
      <template v-slot:timeline="{ day, getTimeScales }">
        <div class="time-wrapper">
          <template v-for="(time, i) in getTimeScales(day)">
            <span :key="i" class="time">{{time.format('HH:mm')}}</span>
          </template>
        </div>
      </template>
      <template v-slot:title> . </template>
    </v-gantt-chart>
  </div>
</template>

<script>

export default {
  props: {
    todayDate: {
      type: String,
      default: '',
      required: true,
    },
    startTime: {
      type: String,
      default: '',
      required: true,
    },
    endTime: {
      type: String,
      default: '',
      required: true,
    },
    executors: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      cellHeight: 52,
      cellWidth: 50,
    };
  },
  mounted() {
    window.addEventListener("resize", this.onPageResize);
    this.onPageResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.onPageResize);
  },
  computed: {
    selectedDate() {
      return DateTime(this.todayDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
    },
    start() {
      return `${this.selectedDate} ${this.startTime}:00`;
    },
    end() {
      return `${this.selectedDate} ${this.endTime}:00`;
    },
  },
  methods: {
    getTimeFormat(time) {
      return DateTime(time).format('hh:mm');
    },
    onPageResize() {
      const pageContnentWidth = document.querySelector('.page-content').clientWidth;

      function styleMedia(from = undefined, to = undefined) {
        if (from && !to) {
          return pageContnentWidth > from + 1;
        } else if (!from && to) {
          return pageContnentWidth <= 1000;
        }
        return pageContnentWidth > from + 1 && pageContnentWidth <= to;
      }

      if (styleMedia(1400, undefined)) {
        this.cellWidth = 75;
      }
      else if (styleMedia(1300, 1400)) {
        this.cellWidth = 65;
      }
      else if (styleMedia(1200, 1300)) {
        this.cellWidth = 60;
      }
      else if (styleMedia(1100, 1200)) {
        this.cellWidth = 55;
      }
      else if (styleMedia(1000, 1100)) {
        this.cellWidth = 50;
      }
      else if (styleMedia(900, 1000)) {
        this.cellWidth = 45;
      }
      else if (styleMedia(undefined, 900)) {
        this.cellWidth = 40;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.schedule-task {
  height: 100%;
  &--active {
    background-color: #41E094;
  }
  &--paused  {
    background-color: #FFD952;
  }
  &--canceled {
    background-color: #FF9290;
  }
  &--day-off {
    background-color: transparentize(#FF6B69, .5);
  }
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.15);
    opacity: 0;
    transition: all 0.2s ease-in-out;
    z-index: 2;
  }
  &:hover {
    &:after {
      opacity: 1;
      cursor: pointer;
    }
  }
}

.time-wrapper{
  display: flex;
}

.time {
  flex:1;
  color: rgba(0, 0, 0, 0.4);
  font-size: 12px;
  display: flex;
  height: 26px;
  justify-content: center;
  align-items: center;
}

::v-deep {
  .gantt-header, .gantt-header-timeline {
    background-color: #F3F3F3;
  }
  .gantt-header-title {
    background-color: #F3F3F3;
    color: #F3F3F3;
    height: 26px !important;
  }
  .gantt-timeline {
    margin-left: 0 !important;
  }
  .gantt-blocks {
    background:
      linear-gradient(#ececec 1px, transparent 0),
      linear-gradient(90deg, #ececec 1px, transparent 0);
  }
}


.schedule-table__employee {
  position: relative;
  padding: 8px 12px 8px 8px;
  .schedule-table__employee-name {
    padding-right: 10px;
    color: #020202;
    font-weight: 600;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .schedule-table__employee-role {
    color: rgba(0, 0, 0, 0.4);
    font-size: 11px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .schedule-table__employee-icon {
    position: absolute;
    top: 5px;
    right: 6px;
    cursor: pointer;
    svg {
      width: 16px;
      height: auto;
      circle {
        transition: all .2s ease-in-out;
      }
    }
    &:hover {
      svg {
        circle {
          fill: var(--color-prime);
        }
      }
    }
  }
}
</style>
