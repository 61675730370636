var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-gantt-chart',{attrs:{"dataKey":"id","start-time":_vm.start,"end-time":_vm.end,"datas":_vm.executors,"cellHeight":_vm.cellHeight,"cellWidth":_vm.cellWidth,"hideXScrollBar":false,"hideYScrollBar":true,"scale":60,"showCurrentTime":true,"timeRangeCorrection":false},scopedSlots:_vm._u([{key:"block",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"schedule-task",class:{
            'schedule-task--active': item.status === _vm.$ENUM.REQUEST_STATUSES.ACTIVE,
            'schedule-task--paused': item.status === _vm.$ENUM.REQUEST_STATUSES.PAUSED,
            'schedule-task--canceled': item.status === _vm.$ENUM.REQUEST_STATUSES.CANCELED,
            'schedule-task--day-off': item.status === 'DAY_OFF',
          }})]}},{key:"left",fn:function(ref){
          var data = ref.data;
return [_c('div',{staticClass:"schedule-table__employee"},[_c('div',{staticClass:"schedule-table__employee-name"},[_vm._v(_vm._s(data.fullName))]),_c('div',{staticClass:"schedule-table__employee-role"}),_c('div',{staticClass:"schedule-table__employee-icon"},[_c('svg',{attrs:{"width":"16","height":"16","viewBox":"0 0 16 16","fill":"none","xmlns":"http://www.w3.org/2000/svg","svg-inline":'',"role":'presentation',"focusable":'false',"tabindex":'-1'}},[_c('circle',{attrs:{"cx":"8","cy":"8","r":"8","fill":"#C4C4C4"}}),_c('path',{attrs:{"d":"M6.977 12.191V5.626h1.732v6.565H6.977zm.87-7.482c-.31 0-.558-.081-.745-.244a.798.798 0 01-.28-.631c0-.262.094-.472.28-.631.187-.163.435-.244.744-.244.31 0 .558.081.744.244.187.159.28.37.28.631a.791.791 0 01-.286.63c-.186.164-.432.245-.738.245z","fill":"#fff"}})])])])]}},{key:"timeline",fn:function(ref){
          var day = ref.day;
          var getTimeScales = ref.getTimeScales;
return [_c('div',{staticClass:"time-wrapper"},[_vm._l((getTimeScales(day)),function(time,i){return [_c('span',{key:i,staticClass:"time"},[_vm._v(_vm._s(time.format('HH:mm')))])]})],2)]}},{key:"title",fn:function(){return [_vm._v(" . ")]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }