<template>
  <div class="schedule-table__legend">
    <div class="schedule-table__legend-title">Заявки:</div>
    <div class="schedule-table__legend-list">
      <div class="schedule-table__legend-ell">
        <div class="schedule-table__legend-bl schedule-item schedule-item--work"></div>
        <div class="schedule-table__legend-text">В работе</div>
      </div>
      <div class="schedule-table__legend-ell">
        <div class="schedule-table__legend-bl schedule-item schedule-item--pause"></div>
        <div class="schedule-table__legend-text">Приостановлена</div>
      </div>
      <div class="schedule-table__legend-ell">
        <div class="schedule-table__legend-bl schedule-item schedule-item--cancel"></div>
        <div class="schedule-table__legend-text">Отменена</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  
};
</script>

<style lang="scss">
.schedule-table__legend {
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  .schedule-table__legend-title {
    margin-right: 20px;
    color: #000000;
    font-weight: 600;
    font-size: 14px;
  }
  .schedule-table__legend-list {
    display: flex;
    align-items: center;
    .schedule-table__legend-ell {
      display: flex;
      align-items: center;
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
      .schedule-table__legend-bl {
        width: 43px;
        height: 24px;
        &.schedule-item {
          &--change {
            &:before {
              left: 2px;
              top: 10px;
              width: 120%;
              height: 100%;
              transform: rotateZ(-28deg);
            }
          }
        }
      }
      .schedule-table__legend-text {
        margin-left: 6px;
        color: #000000;
        font-weight: 600;
        font-size: 14px;
      }
    }
  }
}

.schedule-item {
  position: relative;
  overflow: hidden;
  &--work {
    background-color: #41E094;
  }
  &--pause {
    background-color: #FFD952;
  }
  &--cancel {
    background-color: #FF9290;
  }
}
</style>