<template>
  <div class="page-wrap">
    <div class="page-content">
      <FiltersBtns
        :provider="$ENUM.ASSISTANCE_PROVIDERS_TYPES.COMPANY"
        :responsive="listMode === 'main'"
        :value="selectedReqestStatus"
        @input="setSelectedReqestStatus"
      />
      <div class="employee-schedule">
        <div class="employee-schedule__head">
          <h2 class="page-title">График сотрудников компании “{{companyName}}”</h2>
          <p class="page-text">Просмотр и редактирование загруженности сотрудников</p>
        </div>
        <div class="employee-schedule__body">
          <div class="employee-schedule__date">
            <div class="employee-schedule__date-picker">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'employee-schedule__date-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M6.924 10.482a.431.431 0 00-.431-.431H4.988a.43.43 0 00-.431.43v1.505c0 .239.193.432.43.432h1.506a.431.431 0 00.43-.432v-1.504zM10.683 10.482a.43.43 0 00-.43-.431H8.747a.43.43 0 00-.43.43v1.505c0 .239.192.432.43.432h1.506c.237 0 .43-.193.43-.432v-1.504zM14.445 10.482a.43.43 0 00-.43-.431h-1.506a.431.431 0 00-.43.43v1.505c0 .239.192.432.43.432h1.505a.431.431 0 00.431-.432v-1.504zM6.924 14.244a.431.431 0 00-.431-.431H4.988a.43.43 0 00-.431.43v1.505c0 .238.193.431.43.431h1.506a.431.431 0 00.43-.431v-1.504zM10.683 14.244a.43.43 0 00-.43-.431H8.747a.43.43 0 00-.43.43v1.505c0 .238.192.431.43.431h1.506c.237 0 .43-.193.43-.431v-1.504zM14.445 14.244a.43.43 0 00-.43-.431h-1.506a.431.431 0 00-.43.43v1.505c0 .238.192.431.43.431h1.505c.238 0 .431-.193.431-.431v-1.504z" fill="#FF6B69"/><path d="M17.136 2.116v2.298a1.877 1.877 0 01-1.881 1.876h-1.187a1.887 1.887 0 01-1.893-1.876V2.107h-5.35v2.307A1.887 1.887 0 014.934 6.29H3.746a1.877 1.877 0 01-1.881-1.876V2.116A1.71 1.71 0 00.21 3.817v13.47C.21 18.227.973 19 1.914 19h15.173c.939 0 1.703-.774 1.703-1.713V3.817a1.71 1.71 0 00-1.654-1.701zm-.551 14.332c0 .407-.33.736-.737.736H3.12a.736.736 0 01-.737-.736V9.491c0-.407.33-.737.737-.737h12.729c.406 0 .736.33.736.737v6.957z" fill="#FF6B69"/><path d="M3.741 5.058h1.174a.645.645 0 00.645-.645V.645A.645.645 0 004.915 0H3.74a.645.645 0 00-.645.645v3.768c0 .356.289.645.645.645zM14.053 5.058h1.174a.645.645 0 00.645-.645V.645A.645.645 0 0015.227 0h-1.174a.645.645 0 00-.645.645v3.768c0 .356.29.645.645.645z" fill="#FF6B69"/></svg>
              <DatePicker v-model="todayDate" />

              <TextInput
                v-mask="`##:##`"
                class="ml-4 mr-2 time-picker"
                @input="v => onChangeTime(v, 'startTime')"
                :value="startTime"
              />
              :
              <TextInput
                v-mask="`##:##`"
                class="mr-2 time-picker"
                @input="v => onChangeTime(v, 'endTime')"
                :value="endTime"
              />

            </div>
          </div>
          <TimeLineSchedule
            :key="`${todayDate} ${startTime} ${endTime}`"
            :today-date="todayDate"
            :start-time="startTime"
            :end-time="endTime"
            :executors="executors"
          />
          <ScheduleLegend />
        </div>
      </div>
      <div class="page-content__btn-gp">
        <router-link
          type="button"
          class="btn btn--border"
          :to='{ name: $routeName.APPLICATIONS }'
        >Вернуться к заявкам</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import FiltersBtns from '@component/Module/ProviderReqestList/FiltersBtns.vue';

import DatePicker from '@component/UI/Form/DatePicker';
import TimePicker from '@component/UI/Form/TimePicker';
import ScheduleLegend from '@component/Module/ScheduleLegend.vue';
import TimeLineSchedule from "@component/Module/TimeLineSchedule.vue";
import { mapGetters, mapState } from 'vuex';
import TextInput from "@component/UI/Form/TextInput";

export default {
  name: 'EmployeeSchedule',
  components: {
    FiltersBtns,
    DatePicker,
    TimePicker,
    ScheduleLegend,
    TimeLineSchedule,
    TextInput
  },
  props: {
  },
  data() {
    return {
      todayDate: DateTime().format('DD.MM.YYYY'),
      startTime: '06:00',
      endTime: '21:00',
      executors: [],
      selectedReqestStatus: '',
      listMode: 'main',
    };
  },
  watch: {
    todayDate: {
      handler() {
        this.getExecutors();
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters('user', ['company']),
    ...mapState({
      repeats: state => state.enums.repeats
    }),
    companyName() {
      return this.company?.name;
    },
  },
  methods: {
    onChangeTime(value, propertyName) {
      if (this.maxTimeRule(value) === true && this.correctFormatRule(value) === true) {
        this[propertyName] = value;
        this.updateTime();
      }
    },
    maxTimeRule: function(value) {
      if (value.length < 5) return true;
      let valueParts = value.split(':');
      console.log(+valueParts[0] * 60 + +valueParts[1], valueParts);
      if (+valueParts[1] > 59 || +valueParts[0] > 23) return `Введите корректное время`;
      if (+valueParts[0] * 60 + +valueParts[1] > 1439) return `Максимальное значение 23:59 часа`;
      return true;
      // return value <= 24 || `Максимальное значение 24 часа`;
    },
    correctFormatRule(value) {
      let valueParts = value.split(':');
      if (value.length === 5 && valueParts.length === 2) {
        return true;
      } else {
        return `Введите часы и минуты`;
      }
    },
    updateTime() {
      if (this.startTime && this.endTime && (this.startTime < this.endTime)) {
        this.getExecutors();
      }
    },
    async getExecutors() {
      const params = {};
      if (this.todayDate) {
        params.date = DateTime(this.todayDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
      }
      if (this.startTime && this.startTime) {
        params.start_time = this.startTime;
        params.end_time = this.endTime;
      }
      try {
        const { results } = await RequestManager.Invite.getCompaniesRequests(params);
        await this.$store.dispatch('enums/getRepeats');
        const { date, start_time, end_time} = params;
        const startDate = DateTime(`${ params.date } ${ start_time }`).format('YYYY-MM-DD HH:mm:ss');
        const endDate = DateTime(`${ params.date } ${ end_time }`)
          .add(1, 'hour')
          .format('YYYY-MM-DD HH:mm:ss');
        const executors = results.map(executor => {
          const { id, work_day, active_request_company } = executor;
          const gtArray = [];

          const { key } = this.repeats[DateTime(date, 'YYYY-MM-DD').weekday()];

          if (!(work_day?.length && work_day.find(({ weekday, is_active}) => weekday === key && is_active))) {
            gtArray.push({
              start: startDate,
              end: endDate,
              status: 'DAY_OFF'
            });
          }

          const tasks = active_request_company.map(task => {
              const startTime = DateTime(`${task.date} ${task.time}`, 'DD.MM.YYYY HH:mm').format('YYYY-MM-DD HH:mm');
              const endTime = DateTime(startTime, 'YYYY-MM-DD HH:mm')
                .add(task.hours_per_day, 'hour')
                .format('YYYY-MM-DD HH:mm:ss');

              return {
                start: startTime,
                end: endTime,
                status: task.status,
              };
          });

          gtArray.push(...tasks);

          return {
            id,
            fullName: `${executor?.first_name} ${executor?.last_name}`,
            gtArray
          };

        });

        if (executors) {
          this.executors = executors;
        }

      } finally {}

    },
    async setSelectedReqestStatus(value) {
      this.selectedReqestStatus = value;
      if (this.$route.query?.filters !== value) {
        await this.$router.push({ query: { filters: value } });
      }
    },
  }
};
</script>

<style lang="scss" scoped>

// employee-schedule
.employee-schedule {
  margin-bottom: 50px;
  padding: 40px 26px;
  background: #FFFFFF;
  border-radius: 10px;
  border: 1px solid rgba(118, 118, 128, 0.12);
  .employee-schedule__head {
    margin-bottom: 20px;
  }
  .employee-schedule__body {
    .employee-schedule__date {
      display: flex;
      align-items: baseline;
      margin-bottom: 10px;
      .employee-schedule__date-picker {
        display: flex;
        align-items: center;
        .employee-schedule__date-icon {
          margin-right: 8px;
          path {
            fill: var(--color-prime);
          }
        }
        ::v-deep .v-input {
          width: 100px;
          text-align: center;
          input {
            text-align: center;
          }
        }
      }
      .employee-schedule__today {
        margin-left: 24px;
        color: #999999;
        font-size: 17px;
        line-height: 29px;
        transition: all .2s ease-in-out;
        cursor: pointer;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        &:hover {
          color: #000000;
        }
      }
    }
  }
}

.time-picker {
  max-width: 3em;
}

.ml-4 {
  margin-left: 1.5rem !important;
}

.page-content__btn-gp {
  .btn {
    @media(max-width: 992px) {
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
</style>
