<template>
  <div ref="timePicker">
    <v-menu
      v-model="open"
      :attach="attach ? $refs.timePicker : false"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :rules="rules"
          v-model="time"
          :label="label"
          prepend-icon="mdi-clock-time-four-outline"
          readonly
          v-bind="attrs"
          v-on="on"
        />
      </template>
      <v-time-picker
        v-model="time"
        scrollable
        @input="update"
      />
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'TimePicker',
  model: {
    event: 'update',
    prop: 'value'
  },
  props: {
    label: String,
    value: String,
    attach: Boolean,
    required: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      open: false,
      time: ''
    };
  },
  created() {
    this.time = this.value;
  },
  computed: {
    rules() {
      const rules = [];

      if (this.required) {
        const rule = value => !!value || 'Обязательное поле';
        rules.push(rule);
      }

      return rules;
    }
  },
  watch: {
    value() {
      this.date = this.value;
    }
  },
  methods: {
    update(evt) {
      this.$emit('update', this.time);
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
